import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const MyEditor = ({content,
    
    onChange,
    }) => {


  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(event, editor) => onChange(event, editor)}
      />
    </div>
  );
};

export default MyEditor;



// import React from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// // Import the custom build which includes source code and image upload functionality
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

// const MyEditor = ({ content, onChange }) => {
//   return (
//     <div>
//       <CKEditor
//         editor={BalloonEditor}
//         data={content}
//         config={{
//           toolbar: [
//             'heading', 
//             '|', 
//             'bold', 
//             'italic', 
//             'link', 
//             'bulletedList', 
//             'numberedList', 
//             'blockQuote', 
//             '|',
//             'insertTable', 
//             'undo', 
//             'redo', 
//             'codeBlock', // Add code block feature
//             'imageUpload' // Add image upload feature
//           ],
//           ckfinder: {
//             // Define image upload settings
//             uploadUrl: '/path-to-your-upload-endpoint'
//           }
//         }}
//         onChange={(event, editor) => {
//           const data = editor.getData();
//           onChange(data);
//         }}
//       />
//     </div>
//   );
// };

// export default MyEditor;

