import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Tooltip,
} from 'reactstrap';
import APIServicenew from '../../utils/APIGeneralService';
import { UserContext } from '../../context/UserContextProvider';
import Header from '../../components/Headers/Header';
import userimg from "../../assets/img/icons/userDefault.png"
import { capitalizeFirstLetter, formateDateandtime, formateDatemdy } from '../../utils/Helper';
import PaginatedTable from '../../components/Table/PaginatedTable';

const SubscriptionPaymentList = () => {
  const { id } = useParams();
  const navigate =useNavigate();
  const [donorData, setDonorData] = useState(null);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [limit, setLimit] = useState(10);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const fetchData = async (id) => {
    try {
      const response = await APIServices.get(
        // `/admin/donor/detail/${id}  I-MN84D9V1ANFM`,
        `admin/charity/subscription_payment_list?subscription_id=${id}`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.list || [];
        setDonorData(data1);
        setCount(response?.data?.count)
        const totalItems = response?.data?.count || 0;
        setTotalCount(Math.ceil(totalItems / limit));
      } else if(response?.status === 401){
        logout();
        navigate("/");
       }else {
        setDonorData();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDonorData();
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const columns = [
    {
      dataField: "index",
      text: "S.No.",
      formatter: (cell, row, rowIndex) => {
        const page = currentPage; // Replace with the actual way to get the current page number
        const sizePerPage = limit; // Replace with the actual way to get rows per page
        return (page - 1) * sizePerPage + rowIndex + 1;
      },
    },
    {
        dataField: "reg_number",
        text: "Registration Number",
      },
      {
        dataField: "charity_name",
        text: "Charity Name",
      },
    {
        dataField: "charity_email",
        text: "Charity Email",
      },
      {
          dataField: "phone_number",
          text: "Phone No.",
        },
        {
            dataField: "summary",
            text: "Summary",
            formatter: (cell, row) => (
                <div>
                  <span id={`questionTooltip-${row.id}`}>
                    {capitalizeFirstLetter(row.summary)}
                  </span>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[`questionTooltip-${row.id}`]}
                    target={`questionTooltip-${row.id}`}
                    toggle={() => toggleTooltip(`questionTooltip-${row.id}`)}
                  >
                    {row.summary}
                  </Tooltip>
                </div>
              ),
          },
          {
            dataField: "subscription_id",
            text: "Subscription Id",
          },
          {
            dataField: "transactionid",
            text: "transactionid",
          },
          {
            dataField: "amount",
            text: "amount",
            formatter: (cell, row, rowIndex) => {
              return (
                <span>
                   {row?.currency=="GBP"?"£":""} {cell ? cell : ''} 
                </span>
              );
            },
          },
          {
            dataField: "status",
            text: "status",
            formatter: (cell, row) => {
                const status = capitalizeFirstLetter(row.status);
                let className;
        
                switch (row.status) {
                  case "completed":
                    className = "status-approved"; 
                    break;
                //   case "Rejected":
                //     className = "status-rejected"; 
                //     break;
                //   case "Pending":
                //     className = "status-pending"; 
                //     break;
                  default:
                    className = "status-rejected"; 
                }
        
                return <span className={className}>{status}</span>;
              },
          },
    {
      dataField: "Created Date",
      text: "Created Date",
      formatter: (cell, row) => formateDateandtime(row.create_time),
    },
  ];




  if (!donorData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5 ">
          <Col className=" mb-xl-0" xl="12">
            <Card className="shadow faq-table ">
            <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Subscription Payment List </h3>
                  </div>
                </Row>
              </CardHeader>
              {/* <CardBody className='details-page'> */}
              {donorData && Array.isArray(donorData) && donorData.length !== 0 ? (
                <PaginatedTable
                  data={donorData}
                  columns={columns}
                  totalPages={totalCount}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                <h2 className="text-center mb-4">No Subscriptions Found</h2>
              )}
              
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubscriptionPaymentList;
