import React, { useContext, useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../sideRoutes.js";

import Index from "../pages/index.js";
import UserList from "../pages/userList.js";
import Profile from "../pages/profile.js";
import Document from "../pages/docunent.js";
import UserDetails from "../pages/userDetails.js";
import UserForm from "../pages/addEditUserForm.js";
import Faq from "../pages/Faq/faq.js";
import UpdatePassword from "../pages/Updatepassword/index.js";
import APIServicenew from "../utils/APIGeneralService.js";
import { UserContext } from "../context/UserContextProvider.js";
import Faqform from "../pages/Faq/faqform.js";
import Charity from "../pages/Charity/index.js";
import CharityForm from "../pages/Charity/charityform.js";
import Donor from "../pages/Donor/index.js";
import DonorForm from "../pages/Donor/donorform.js";
import Staticpage from "../pages/Pagestatic/index.js";
import PrivacyFrom from "../pages/Pagestatic/privacyupdate.js";
import AboutFrom from "../pages/Pagestatic/about.js";
import TermsFrom from "../pages/Pagestatic/term.js";
import Subadmin from "../pages/Subadmin/index.js";
import SubadminForm from "../pages/Subadmin/subadminform.js";
import Charitieslist from "../pages/Charitieslist/index.js";
import Donorlist from "../pages/Donorlist/index.js";
import ContactUs from "../pages/Contactus/index.js";
import CharityDetail from "../pages/Charitieslist/charitydetail.js";
import DonorDetail from "../pages/Donorlist/donordetail.js";
import Subscribe from "../pages/Subscribe/index.js";
import ReplyContact from "../pages/Contactus/contactreply.js";
import CharityRequest from "../pages/Charityrequest/index.js";
import Role from "../pages/Role/index.js";
import RolePermissionForm from "../pages/Role/rolepermission.js";
import RolePermissionFormUpdate from "../pages/Role/rolepermissionupdate.js";
import Questionmanage from "../pages/Questionmanage/index.js";
import QuestionForm from "../pages/Questionmanage/questionform.js";
import EmailTemplate from "../pages/Emailtemplate/index.js";
import Edittemplate from "../pages/Emailtemplate/edittemplate.js";
import Payment from "../pages/Payment/payment.js";
import SubscriptionPaymentList from "../pages/Payment/subscriptionpaymentlist.js";
import SiteSetting from "../pages/Sitesetting.js/index.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const fullPath = window.location.pathname;
  let pathname = fullPath.substring(fullPath.lastIndexOf("/") + 1);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (`/${pathname}` === routes[i].path) {
        return routes[i].name;
      }
    }
    return "";
  };

 

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        // logo={{
        //   innerLink: "/admin/index",
        //   imgSrc: require("../assets/img/brand/argon-react.png"),
        //   imgAlt: "...",
        // }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          titleText={getBrandText(props?.location?.pathname)}
        />
        <Routes>
          {/* {getRoutes(routes)} */}
          <Route
            path="*"
            element={<Navigate to="/admin/dashboard" replace />}
          />
          <Route path="/dashboard" element={<Index />} exact />
          {/* <Route path="/user-list" element={<UserList />} exact /> */}
          {/* <Route path="/document" element={<Document />} exact /> */}
          <Route path="/user-details" element={<UserDetails />} exact />
          {/* <Route path="/user-form" element={<UserForm />} exact /> */}
          <Route path="/faq" element={<Faq />} exact />
          <Route path="/faq/add" element={<Faqform />} exact />
          <Route path="/faq/update/:id" element={<Faqform />} exact />
          <Route path="/charity-categories" element={<Charity />} exact />
          <Route
            path="/charity-categories/add"
            element={<CharityForm />}
            exact
          />
          <Route
            path="/charity-categories/update/:id"
            element={<CharityForm />}
            exact
          />
          <Route path="/donor-categories" element={<Donor />} exact />
          <Route path="/donor-categories/add" element={<DonorForm />} exact />
          <Route
            path="/donor-categories/update/:id"
            element={<DonorForm />}
            exact
          />

          <Route path="/pages" element={<Staticpage />} exact />
          <Route path="/profile" element={<Profile />} exact />
          <Route path="/pages/privacy-form" element={<PrivacyFrom />} exact />
          <Route path="/pages/term-form" element={<TermsFrom />} exact />
          <Route path="/pages/about-form" element={<AboutFrom />} exact />
          <Route path="/update-password" element={<UpdatePassword />} exact />
          <Route path="/roles" element={<Role />} exact />
          <Route path="/roles/rolepermission-add" element={<RolePermissionForm />} exact />
          <Route path="/roles/rolepermission-update/:id" element={<RolePermissionFormUpdate />} exact />
          
          <Route path="/subadmin" element={<Subadmin />} exact />
          <Route path="/subadmin/add" element={<SubadminForm />} exact />
          <Route path="/subadmin/update/:id" element={<SubadminForm />} exact />

          <Route path="/charities" element={<Charitieslist />} exact />
          <Route path="/charities/detail/:id" element={<CharityDetail/>} exact />

          <Route path="/donors" element={<Donorlist />} exact />
          <Route path="/donors/detail/:id" element={<DonorDetail />} exact />


          
          <Route path="/contact-us" element={<ContactUs />} exact />
          <Route path="/contact-us/reply/:id" element={<ReplyContact />} exact />
          <Route path="/subscriptions" element={<Subscribe />} exact />
          <Route path="/charity-requests" element={<CharityRequest />} exact />

          <Route path="/questions-manage" element={<Questionmanage />} exact />
          <Route path="/questions-manage/add" element={<QuestionForm />} exact />
          <Route path="/questions-manage/update/:id" element={<QuestionForm />} exact />

          <Route path="/email-templates" element={<EmailTemplate />} exact />
          <Route path="/email-templates/update/:id" element={<Edittemplate />} exact />

          <Route path="/payments" element={<Payment />} exact />
          <Route path="/payments/:id" element={<SubscriptionPaymentList />} exact />

          <Route path="/site-settings" element={<SiteSetting />} exact />

        </Routes>
{/* 
        <Container fluid className="pl-0">
          <AdminFooter />
        </Container> */}
          <AdminFooter />

      </div>
    </>
  );
};

export default Admin;
