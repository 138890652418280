import { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";


export default function SiteSetting() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Email: "",
    Address:"",
    Instagram: "",
    Facebook: "",
    Twitter: "",
    Linkedin: ""
  });
  const [errors, setErrors] = useState({});
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
        email: formData.Email,
        address:formData.Address,
        instagram: formData.Instagram,
        facebook: formData.Facebook,
        twitter: formData.Twitter,
        linkedin: formData.Linkedin
    };
    try {
        // console.log(payload);
        // return
      const response = await APIServices.post(
        `/admin/settings/update_site_setting`,  
        payload,
        headers
      );
      if (response?.data?.status === true) {
        showToast(response?.data?.message || "Success", "success");
        fetchData();
      } else {
        showToast(
          response?.data?.message || "Something went wrong.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await APIServices.get(`/admin/settings/site_setting`, headers); 
      if (response?.status === 200) {
        const fetchedData = response?.data?.list;
        // setFormData({
        //     Email: fetchedData.type=="email"?fetchedData[0].value:"",
        //     Address:fetchedData.type=="address"?fetchedData[2].value:"",
        //     Instagram: fetchedData.type=="instagram"?fetchedData[8].value:"",
        //     Facebook:fetchedData.type=="facebook"?fetchedData[5].value:"",
        //     Twitter: fetchedData.type=="twitter"?fetchedData[6].value:"",
        //     Linkedin: fetchedData.type=="linkedin"?fetchedData[7].value:""
        //   });  // Set the form data
        const formMapping = fetchedData.reduce((acc, item) => {
          switch (item.type) {
            case "email":
              acc.Email = item.value;
              break;
            case "address":
              acc.Address = item.value;
              break;
            case "instagram":
              acc.Instagram = item.value;
              break;
            case "facebook":
              acc.Facebook = item.value;
              break;
            case "twitter":
              acc.Twitter = item.value;
              break;
            case "linkedin":
              acc.Linkedin = item.value;
              break;
            default:
              break;
          }
          return acc;
        }, {});
  
        // Update the formData state
        setFormData(formMapping);
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();  // Fetch the data when the component mounts
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0 card-title">Site Settings</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="input-email">
                          Email
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          name="Email"
                          placeholder="Enter Email"
                          type="text"
                          value={formData.Email}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="input-address">
                          Address
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          name="Address"
                          placeholder="Enter Address"
                          type="text"
                          value={formData.Address}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="input-instagram">
                          Instagram
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-instagram"
                          name="Instagram"
                          placeholder="Enter Instagram"
                          type="text"
                          value={formData.Instagram}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="input-facebook">
                          Facebook
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-facebook"
                          name="Facebook"
                          placeholder="Enter Facebook"
                          type="text"
                          value={formData.Facebook}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="input-twitter">
                          Twitter
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-twitter"
                          name="Twitter"
                          placeholder="Enter Twitter"
                          type="text"
                          value={formData.Twitter}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="input-linkedin">
                          Linkedin
                        </Label>
                        <Input
                          className="form-control-alternative"
                          id="input-linkedin"
                          name="Linkedin"
                          placeholder="Enter Linkedin"
                          type="text"
                          value={formData.Linkedin}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                  {/* <Button
                    color="light"
                    onClick={() => navigate("/admin/email-templates")}
                  >
                    Back
                  </Button> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};
