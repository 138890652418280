// ContactUs.js
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import PaginatedTable from "../../components/Table/PaginatedTable";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  formateDate,
  formateDateandtime,
} from "../../utils/Helper";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import useMenuPermissions from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Not-authorized/notauthorized";
import Papa from "papaparse";

const Payment = () => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken,logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [limit, setLimit] = useState(10);

  const [data1, setData1] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [charityname, setCharityname] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [regnumber, setRegnumber] = useState("");
  const [planid, setPlanid] = useState("");
  const [subscribeid, setSubscribeid] = useState("");
  const dropdownRef = useRef(false);
//   const statusRef = useRef("Select Status");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("");
  const [reset, setReset] = useState(false);
  const permissionscontext = useMenuPermissions('payments');

  const toggleDropdown = () => {
    dropdownRef.current = !dropdownRef.current;
    setData1((prev) => [...prev]);
  };

  const fetchData = async (page = 1) => {
    const skip = page;
    // const encodedStatus =
    //   statusRef.current === "Select Status" ? "" : statusRef.current;
    try {
      // &skip=${skip}&limit=${limit}&status=${encodedStatus}
      const response = await APIServices.get(
        `admin/charity/subscription_list?offset=${skip}&limit=${limit}&charity_name=${charityname}&reg_number=${regnumber}&plan_id=${planid}&subscription_id=${subscribeid}&phone_number=${phoneno}&from_date=${
              startDate ? formateDate(startDate) : ""
            }&to_date=${
              endDate ? formateDate(endDate) : ""
            }`,
        headers
      );
      if (response?.status === 200) {
        const data1 = response?.data?.list || [];
        setData1(data1);
        setCount(response?.data?.count)
        const totalItems = response?.data?.count || 0;
        setTotalCount(Math.ceil(totalItems / limit));
      }else if(response?.status === 401){
        logout();
        navigate("/");
       } else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);


//   const handleSelect = (status) => {
//     statusRef.current = status;
//   };

  const refreshData = () => {
    setCurrentPage(1);
    fetchData(1);
  };

//   const handleStatusChange = async (faq) => {
//     const newStatus = faq.status === "Active" ? "Inactive" : "Active";
//     // const updatedDate = new Date().toISOString();
//     try {
//       const payload = { id: faq.id, status: newStatus };
//       const res = await APIServices.post(
//         `/admin/contactus/subscriber_update`,
//         payload,
//         headers
//       );
//       // ,modified: updatedDate
//       if (res?.data?.status === true) {
//         showToast(res?.data?.message || "Success", "success");
//         const updatedFaqs = data1.map((item) =>
//           item.id === faq.id ? { ...item, status: newStatus } : item
//         );
//         setData1(updatedFaqs);
//       } else {
//         showToast(res?.data?.message || "Something went wrong.", "error");
//       }
//     } catch (error) {
//       console.error("Error updating status:", error);
//       showToast("Error updating status", "error");
//     }
//   };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const columns = [
    {
      dataField: "index",
      text: "S.No.",
      formatter: (cell, row, rowIndex) => {
        const page = currentPage; // Replace with the actual way to get the current page number
        const sizePerPage = limit; // Replace with the actual way to get rows per page
        return (page - 1) * sizePerPage + rowIndex + 1;
      },
    },
    {
        dataField: "reg_number",
        text: "Registration Number",
      },
      {
        dataField: "charity_name",
        text: "Charity Name",
      },
    {
        dataField: "charity_email",
        text: "Charity Email",
      },
      {
          dataField: "phone_number",
          text: "Phone No.",
        },
        {
          dataField: "shipping_address",
          text: "shipping Address",
        },
        {
            dataField: "summary",
            text: "Summary",
          },
          {
            dataField: "subscription_id",
            text: "Subscription Id",
          },
          {
            dataField: "plan_id",
            text: "Plan Id",
          },
          {
            dataField: "last_payment",
            text: "Last Payment",
            formatter: (cell, row, rowIndex) => {
              return (
                <span>
                  {row?.currency=="GBP"?"£":""} {cell ? cell : ''} 
                </span>
              );
            },
          },
    {
      dataField: "Created Date",
      text: "Created Date",
      formatter: (cell, row) => formateDateandtime(row.created),
    },
    {
        dataField: "Subscription Payment List",
        text: "Subscription Payment List",
        formatter: (cell, row, rowIndex) => (
          <Button
            color="light"
            onClick={() => navigate(`/admin/payments/${row.subscription_id}`)}
            size="sm"
            className="light"
            disabled={!permissionscontext.edit}
          >
           Subscription Payment List
          </Button>
        ),
      },
  ];

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const handleReset = () => {
    setCharityname("");
    setPhoneno("");
    setRegnumber("");
    setPlanid("");
    setSubscribeid("");
    setReset(true);
    setStartDate(null);
    setEndDate(null);
    setSelectedRange("");
    // handleSelect("Select Status");
  };

  useEffect(() => {
    if (reset) {
      fetchData();
      setCurrentPage(1)
      setReset(false);
    }
  }, [reset]);




  const handleExportCSV = async() => {
    const offset = 0;
    // const encodedStatus =
    // statusRef.current === "Select Status" ? "" : statusRef.current;
    const response = await APIServices.get(
        `admin/charity/subscription_list?offset=${offset}&limit=${10000}&charity_name=${charityname}&reg_number=${regnumber}&plan_id=${planid}&subscription_id=${subscribeid}&phone_number=${phoneno}&from_date=${
            startDate ? formateDate(startDate) : ""
          }&to_date=${
            endDate ? formateDate(endDate) : ""
          }`,
      headers
    );
      if (response?.status === 200) {
    const csvData = response?.data?.list.map((item, index) => ({
      "S.No": index + 1,
      "Registration Number": item.reg_number,
      "charity Name":item.charity_name,
      "charity Email":item.charity_email,
      "Phone Number":item.phone_number,
      "Shipping Address":item.shipping_address,
      "Summary":item.summary,
      "Subscription Id":item.subscription_id,
      "Plan Id":item.plan_id,
      "Last Payment":`${item.currency=="GBP"?"£":""} ${item.last_payment}`,
      "Created Date": formateDateandtime(item.created),
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "Payment List.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  if(response?.status === 401){
    logout();
    navigate("/");
   }

  };

  return (
    <>
    {permissionscontext.view ? <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className=" mb-xl-0" xl="12">
            <Card className="shadow faq-table ">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Payments <small>({count})</small></h3>
                  </div>
                </Row>
              </CardHeader>
              <div className="select-filter-wrapper mb-0">
                <Input
                  className="w-20"
                  placeholder="Search by charity name"
                  value={charityname}
                  type="text"
                  onChange={(e) => setCharityname(e.target.value)}
                />
                <Input
                  className="w-20"
                  placeholder="Search by phone number"
                  value={phoneno}
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setPhoneno(inputValue); 
                    }
                  }}
                />
                 <Input
                  className="w-20"
                  placeholder="Search by plan id"
                  value={planid}
                  type="text"
                //   maxLength={10}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // if (/^\d*$/.test(inputValue)) {
                      setPlanid(inputValue); 
                    // }
                  }}
                />
                 <Input
                  className="w-20"
                  placeholder="Search by registration number"
                  value={regnumber}
                  type="text"
                //   maxLength={10}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setRegnumber(inputValue); 
                    }
                  }}
                />
                 
                   {/* <ButtonDropdown
                className="dropdown-btn-wrapper"
                  isOpen={dropdownRef.current}
                  toggle={toggleDropdown}
                > */}
                  {/* className="btn-default" */}
                  {/* <DropdownToggle className="dropdown-btn select-input" caret>{statusRef.current}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleSelect("Select Status")}>
                      Select Status
                    </DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Active")}>
                      Active
                    </DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Inactive")}>
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown> */}
                
             
              </div>
              <div className="select-filter-wrapper">
              <Input
                  className="w-25"
                  placeholder="Search by subscription id"
                  value={subscribeid}
                  type="text"
                //   maxLength={10}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // if (/^\d*$/.test(inputValue)) {
                      setSubscribeid(inputValue); 
                    // }
                  }}
                />
              <select
                  className="select-input"
                  value={selectedRange}
                  onChange={(e) => handleDateRange(e.target.value)}
                >
                  <option value="disabled">Select Date Range</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="thisWeek">This Week</option>
                  <option value="last7Days">Last 7 Days</option>
                  <option value="thisMonth">This Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="customDate">Custom Date</option>
                </select>

                {selectedRange === "customDate" && (
                  <DatePicker
                    className="select-input"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    onChange={(update) => {
                      const [start, end] = update;
                      setStartDate(start);
                      setEndDate(end);
                    }}
                    // isClearable={true}
                    placeholderText="Select custom date range"
                    dateFormat="yyyy-MM-dd"
                  />
                )}
                <Button
                color="primary"
                  onClick={(e) => refreshData(e)}
                  size="md"
                  className="filter mr-0"
                >
                  <i className="fa fa-filter"></i> Filter
                  {/* <i className="fa fa-refresh fa-spin"></i>  */}
                </Button>
                <Button
                  color="light"
                  onClick={(e) => handleReset(e)}
                  size="md"
                  className="reset mx-0"
                >
                  <i className="fa fa-reset"></i> Reset
                </Button>
                <Button
                 className="export btn-grey ml-0"
                 onClick={handleExportCSV}
                 size="md"
                 disabled={data1.length == 0}
               >
                 Export CSV
               </Button>
              </div>
              {data1 && Array.isArray(data1) && data1.length !== 0 ? (
                <PaginatedTable
                  data={data1}
                  columns={columns}
                  totalPages={totalCount}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                <h2 className="text-center mb-4">No Subscriptions Found</h2>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
      </>: (
       <Notauthorized />
      )}
    </>
  );
};

export default Payment;
